import { Component, OnInit } from '@angular/core';
import Api from "../helpers/api";
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from 'app/helpers/excel.service';
import Me from 'app/helpers/me';
import App from 'app/helpers/app';
import { HttpClient } from '@angular/common/http';
import { create } from 'xmlbuilder';


@Component({
  selector: 'app-sapiem',
  templateUrl: './sapiem.component.html',
  styleUrls: ['./sapiem.component.scss']
})
export class SapiemComponent implements OnInit {
  endereco: any;
  documentos: any;
  contatos: any;
  beneficios: any;
  allBenefitsValid: any;
  allDataValid: any;
  movimentosMensais: any;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private http: HttpClient,
    private api: Api
  ) {}
  apiCall = this.api.new().silent();
  page = 1;
  pageSize = 10;
    nomeCidade : string;
    isAdmin = false;
    sortColumn: string = '';
    sortDirection: string = 'asc'; 
    servidor = {
      cpf: null,
      cidade_naturalidade: null,
      data_ingresso_servico_publico: null,
      data_nascimento: null,
      deficiente: null,
      data_falecimento: null,
      documento_ingresso_servico_publico: null,
      escolaridade: null,
      estado_civil: null,
      estado_naturalidade: null,
      id_cidade: null,
      nacionalidade: null,
      naturalizado: null,
      nome: null,
      nome_mae: null,
      nome_pai: null,
      sexo: null,
      status: null,
      paridade_aposentadoria: null,
      concessao_aposentadoria: null,
      valor_aposentadoria: null,
      cargo_aposentadoria: null,
      pis_aposentado: null,
      tipo_aposentadoria: null,
      cpf_pensao: null,
      pis_pensao: null,
      nome_pensao: null,
      matricula_pensao: null,
      data_nascimento_pensao: null,
      data_falecimento_pensao: null,
      matricula_beneficiario: null,
      tipo_pensao: null,
      valor_beneficio: null,
      motivo_exoneracao: null,
      telefone: null,
      matricula: null,
      carga_horaria: null,
      imagem: null,
      rg: null,
      salario: null,
      cargo: null
    };
    missingFields: string[] = []; // Armazena os campos faltantes

    status = [
      {
        id: true,
        name: "Ativo",
      },
      {
        id: false,
        name: "Inativo",
      },
    ];
    tempoTotalCadastrado = 0
    filterName;
    filterCpf;
    filterMatricula;
    filterNascimento;
    filterCadastro;
    filterSexo;
    filterStatus;
    idCidade;
    cidade = null;
    estados = [];
    cidades = [];
    estadosCivis = [];
    dependentes = [];
    dependentesToShow = [];
    escolaridades = [];
    tiposDependencia = [];
    planilhas = [];
    tempos = [];
    servidoresFiltered = [];
    servidores = [];

    async ngOnInit() {
      const self = this;
      self.servidor = {
        cpf: null,
        cidade_naturalidade: null,
        data_ingresso_servico_publico: null,
        data_nascimento: null,
        deficiente: null,
        documento_ingresso_servico_publico: "",
        escolaridade: null,
        data_falecimento: null,
        estado_civil: null,
        estado_naturalidade: null,
        id_cidade: null,
        nacionalidade: null,
        naturalizado: null,
        nome: null,
        nome_mae: null,
        nome_pai: null,
        sexo: null,
        status: null,
        paridade_aposentadoria: null,
        concessao_aposentadoria: null,
        valor_aposentadoria: null,
        cargo_aposentadoria: null,
        pis_aposentado: null,
        tipo_aposentadoria: null,
        cpf_pensao: null,
        pis_pensao: null,
        nome_pensao: null,
        matricula_pensao: null,
        data_nascimento_pensao: null,
        data_falecimento_pensao: null,
        matricula_beneficiario: null,
        tipo_pensao: null,
        valor_beneficio: null,
        motivo_exoneracao: null,
        telefone: null,
        matricula: null,
        carga_horaria: null,
        imagem: null,
        rg: null,
        salario: null,
        cargo: null
      };
      self.tempoTotalCadastrado = 0
      self.estados = [];
      self.cidades = [];
      self.estadosCivis = [];
      self.escolaridades = [];
      const dadosUsuario = self.me.currentUserValue;
      const json = JSON.parse(JSON.stringify(dadosUsuario));
      const cidade = json.user.cidade;
      console.log('json.user', json.user)
      self.nomeCidade = json.user.nomeCidade;
      self.isAdmin = json.user.tipo === "1";
      if (cidade) {
        self.idCidade = cidade;
      }
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
  
      if (json.user.tipo === "1") {
        console.log("caiu if")
        self.route.queryParams.subscribe(async (parametros) => {
          if (parametros["cidade"]) {
            let idCidade = parametros["cidade"];
            self.idCidade = idCidade;
            json.user.cidade = idCidade;
            self.me.atualizaCidade(json);
            const planilhas = await self.apiCall
              .get("servidor/arquivo/planilha?id_cidade=" + self.idCidade)
              .pipe(take(1))
              .toPromise();
            const responseCidade = await self.apiCall
              .get("enderecos/cidade?id=" + self.idCidade)
              .pipe(take(1))
              .toPromise();
            self.planilhas = JSON.parse(JSON.stringify(planilhas));
            if (responseCidade && responseCidade[0])
              self.cidade = responseCidade[0];
              self.nomeCidade = self.cidade.nome;
            self.apiCall
              .get("servidor/todos/cidade?id_cidade=" + idCidade)
              .subscribe((data) => {
                let lista = data
                ? JSON.parse(JSON.stringify(data)).reverse()
                : []
                let listafinal = []
                if(lista.length>0){
                  let lista1 = lista.filter(x=>x.temDependenteMaiorDe21)
                  let lista2 = lista.filter(x=>x.temTetoINSS)
                  let lista3 = lista.filter(x=>x.pendencias)
                  listafinal = [...lista1]
                  for(let l of lista2){
                    if(!listafinal.find(x=>x==l)){
                      listafinal.push(l)
                    }
                  }
                  for(let l of lista3){
                    if(!listafinal.find(x=>x==l)){
                      listafinal.push(l)
                    }
                  }
                  for(let l of lista){
                    if(!listafinal.find(x=>x==l)){
                      listafinal.push(l)
                    }
                  }
                  for(let l of listafinal){
                    let duasmatriculas = listafinal.filter(x=>x.cpf&&x.cpf==l.cpf)
                    if(duasmatriculas.length>1){
                      l.duasmatriculas = true
                    }
                  }
                }
  
              
              
                self.servidores =[...listafinal];
                self.servidoresFiltered =[...listafinal];
                console.log(listafinal, lista.length)
                self.spinner.hide();
              });
          } else {
            console.log("caiu else")
            self.apiCall
              .get("servidor/todos/cidade?id_cidade=" + cidade)
              .subscribe((data) => {
                let lista = data
                ? JSON.parse(JSON.stringify(data)).reverse()
                : []
                let listafinal = []
                if(lista.length>0){
                  let lista1 = lista.filter(x=>x.temDependenteMaiorDe21)
                  let lista2 = lista.filter(x=>x.temTetoINSS)
                  let lista3 = lista.filter(x=>x.pendencias)
                  listafinal = [...lista1]
                  for(let l of lista2){
                    if(!listafinal.find(x=>x==l)){
                      listafinal.push(l)
                    }
                  }
                  for(let l of lista3){
                    if(!listafinal.find(x=>x==l)){
                      listafinal.push(l)
                    }
                  }
                  for(let l of lista){
                    if(!listafinal.find(x=>x==l)){
                      listafinal.push(l)
                    }
                  }
                  for(let l of listafinal){
                    let duasmatriculas = null
                    duasmatriculas = listafinal.filter(x=>x.cpf==l.cpf)
    
                    if(duasmatriculas.length>1){
                      l.duasmatriculas = true
                    }
                  }
                }
                self.servidores =[...listafinal];
                self.servidoresFiltered =[...listafinal];
                console.log('ta aq', listafinal)
                self.spinner.hide();
              });
          }
        });
      } else {
        console.log("Else do else")
        const planilhas = await self.apiCall
          .get("servidor/arquivo/planilha?id_cidade=" + cidade)
          .pipe(take(1))
          .toPromise();
        self.planilhas = JSON.parse(JSON.stringify(planilhas));
        self.apiCall
          .get("servidor/todos/cidade?id_cidade=" + cidade)
          .subscribe((data) => {
  
            let lista = data  ? JSON.parse(JSON.stringify(data)).reverse() : []
  
            for(let l of lista){
              let duasmatriculas = null
              duasmatriculas = lista.filter(x=>x.cpf==l.cpf)
  
              if(duasmatriculas.length>1){
                l.duasmatriculas = true
              }
            }
  
            self.servidores = lista;
            self.servidoresFiltered = lista;
  
  
            /*self.servidores = data
              ? JSON.parse(JSON.stringify(data)).reverse()
              : [];
            self.servidoresFiltered = data
              ? JSON.parse(JSON.stringify(data)).reverse()
              : [];*/
            self.spinner.hide();
          });
      }
  
      const estados = await self.apiCall
        .get("enderecos/estados")
        .pipe(take(1))
        .toPromise();
      self.estados = JSON.parse(JSON.stringify(estados));
      const escolaridades = await self.apiCall
        .get("servidor/escolaridade")
        .pipe(take(1))
        .toPromise();
      self.escolaridades = JSON.parse(JSON.stringify(escolaridades));
      const estadosCivis = await self.apiCall
        .get("servidor/estadocivil")
        .pipe(take(1))
        .toPromise();
      self.estadosCivis = JSON.parse(JSON.stringify(estadosCivis));
    }
    classificaStatus(status){
      if(!status){
        return ''
      }
      if(status==1){
        return 'Ativo'
      }
      if(status==2){
        return 'Aposentado'
      }
      if(status==3){
        return 'Pensionista'
      }
      if(status==4){
        return 'Exonerado'
      }
      if(status==5){
        return 'Falecido'
      }
    }
    getColor(servidor){
      if(servidor.pendencias){
        return '#ffcfcf'
      }
      if(servidor.temDependenteMaiorDe21){
        return '#ffffcb'
      }
      if(servidor.temTetoINSS){
        return '#d9eeff'
      }
      return 'none'
    }
    sortTable(column: string) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
    
      this.servidoresFiltered.sort((a, b) => {
        let valueA, valueB;
    
        if (column === 'matricula') {
          valueA = a.status == 2 || a.status == 3 ? a.matricula_beneficiario : a.matricula;
          valueB = b.status == 2 || b.status == 3 ? b.matricula_beneficiario : b.matricula;
        } else if (column === 'status') {
          valueA = this.classificaStatus(a.status);
          valueB = this.classificaStatus(b.status);
        } else {
          valueA = a[column]?.toString().toLowerCase() || '';
          valueB = b[column]?.toString().toLowerCase() || '';
        }
    
        if (this.sortDirection === 'asc') {
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        } else {
          return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
        }
      });
    }
  async toggleEstado() {
    if (!this.servidor.estado_naturalidade) {
      return;
    }
    this.cidades = [];
    const cidades = await this.apiCall
      .get(
        "enderecos/estados/cidades?estado=" + this.servidor.estado_naturalidade
      )
      .pipe(take(1))
      .toPromise();
    this.cidades = JSON.parse(JSON.stringify(cidades));
  }

  selectFilter(event) { 
    this.filterName = null;
    this.filterCpf = null;
    this.filterMatricula = null;
    this.filterNascimento = null;
    this.filterCadastro = null;
    this.filterSexo = null;
    this.filterStatus = null;
    this.servidoresFiltered = this.servidores;
  }

  applyFilters() {
    this.servidoresFiltered = this.servidores.filter(servidor => {
      const matchesNome = this.servidor.nome ? servidor.nome.toLowerCase().includes(this.servidor.nome.toLowerCase()) : true;
      const matchesCpf = this.servidor.cpf ? servidor.cpf.includes(this.servidor.cpf) : true;
      const matchesMatricula = this.servidor.matricula ? servidor.matricula.includes(this.servidor.matricula) : true;
      const matchesStatus = this.servidor.status ? servidor.status === this.servidor.status : true;
  
      return matchesNome && matchesCpf && matchesMatricula && matchesStatus;
    });
  }

  clearFilters() {
    // Reseta os campos do objeto de filtro   
  
    // Restaura a lista filtrada com todos os servidores
    this.servidoresFiltered = [...this.servidores];
  
    // Reseta a página para a primeira
    this.page = 1;
  }

  async generateDocument(){

  }

async loadData(idServidor: number, idCidade?: number): Promise<void> {
  try {
    const servidor = await this.apiCall.get(`servidor?id=${idServidor}&id_cidade=${this.idCidade}`).pipe(take(1)).toPromise();
    if (servidor) this.servidor = servidor;

    const endereco = await this.apiCall.get(`servidor/endereco?id_servidor=${idServidor}`).pipe(take(1)).toPromise();
    if (endereco) this.endereco = endereco;

    const documento = await this.apiCall.get(`servidor/documentos?id_servidor=${idServidor}`).pipe(take(1)).toPromise();
    if (documento) this.documentos = documento;

    const contatos = await this.apiCall.get(`servidor/contatos?id_servidor=${idServidor}`).pipe(take(1)).toPromise();
    if (contatos) this.contatos = contatos;

    let beneficios = await this.apiCall.get(`sapiem/beneficio/${idServidor}`).pipe(take(1)).toPromise();
    if (beneficios) {
      this.beneficios = await this.loadAdditionalDataForBeneficios(beneficios);

      this.beneficios = this.beneficios.map((benefit: any) => ({
        ...benefit,
        isValid: this.validateBenefit(benefit),
      }));

      this.allBenefitsValid = this.beneficios.every((benefit: any) => benefit.isValid);
    }

    console.log("beneficios", this.beneficios);
  } catch (error) {
    console.error('Erro ao carregar os dados do servidor:', error);
  }
}


private async loadAdditionalDataForBeneficios(
  beneficios: any[]
): Promise<any[]> {
  // Itera sobre cada benefício e adiciona os dados adicionais
  const beneficiosCompletos = await Promise.all(
    beneficios.map(async (beneficio: any) => {
      const idBeneficio = beneficio.id;

      try {
        // Busca os dados adicionais para o benefício
        const [assentamentos, exerciciosMagisterio, averbacoes, remuneracoes] =
          await Promise.all([
            this.apiCall
              .get(`sapiem/assentamento/${idBeneficio}`)
              .pipe(take(1))
              .toPromise()
              .catch(() => []), // Retorna array vazio em caso de erro
            this.apiCall
              .get(`sapiem/magisterio/${idBeneficio}`)
              .pipe(take(1))
              .toPromise()
              .catch(() => []),
            this.apiCall
              .get(`sapiem/averbacao/${idBeneficio}`)
              .pipe(take(1))
              .toPromise()
              .catch(() => []),
            this.apiCall
              .get(`sapiem/remuneracao/${idBeneficio}`)
              .pipe(take(1))
              .toPromise()
              .catch(() => []),
          ]);

        // Retorna o benefício com os dados adicionais
        return {
          ...beneficio,
          assentamentos: assentamentos || [],
          exerciciosMagisterio: exerciciosMagisterio || [],
          averbacoes: averbacoes || [],
          remuneracoes: remuneracoes || [],
        };
      } catch (error) {
        console.error(`Erro ao carregar dados adicionais para o benefício ${idBeneficio}:`, error);

        // Retorna o benefício original mesmo em caso de erro
        return {
          ...beneficio,
          assentamentos: [],
          exerciciosMagisterio: [],
          averbacoes: [],
          remuneracoes: [],
        };
      }
    })
  );
  console.log('Benefícios completos:', beneficiosCompletos);
  return beneficiosCompletos;
}

// Método para validar um benefício
validateBenefit(benefit: any): boolean {
  const { especie, assentamentos, remuneracoes, averbacoes, exerciciosMagisterio } = benefit;

  if (!especie) {
    console.error("Benefício sem espécie identificada:", benefit);
    return false;
  }

  // Validações para benefícios que não são da espécie 22
  const hasValidAssentamento = assentamentos?.some((item: any) =>
    item && Object.values(item).every((value) => value !== null && value !== undefined && value !== '')
  );

  const hasValidRemuneracao = remuneracoes?.some((item: any) =>
    item && Object.values(item).every((value) => value !== null && value !== undefined && value !== '')
  );

  const hasValidAverbacao = averbacoes?.some((item: any) =>
    item && Object.values(item).every((value) => value !== null && value !== undefined && value !== '')
  );

  if (especie !== 22) {
    return hasValidAssentamento && hasValidRemuneracao && hasValidAverbacao;
  }

  // Validações adicionais para benefícios da espécie 22
  const hasValidMagisterio = exerciciosMagisterio?.some((item: any) =>
    item && Object.values(item).every((value) => value !== null && value !== undefined && value !== '')
  );

  return hasValidAssentamento && hasValidRemuneracao && hasValidAverbacao && hasValidMagisterio;
}


generateXML(data: any): string {
  console.log('Gerando XML com os dados:', data);

  const normalizedData = Array.isArray(data) ? data : [data];
  const docs = this.documentos;
  const end = this.endereco;

  console.log(this.beneficios)

  const xmlObject = {
    sapiem: {
      servidor: normalizedData.map((servidor: any) => ({
        NOME: servidor.nome,
        CPF: servidor.cpf,
        SEXO: servidor.sexo,
        NACIONALIDADE: servidor.nacionalidade,
        DT_NASCIMENTO: servidor.dt_nascimento,
        NM_MAE: servidor.nome_mae,
        NM_PAI: servidor.nome_pai,
        ESTADO_CIVIL: servidor.estado_civil, 
        OUTROS_NOMES: servidor.nome,
        TIPO_IDENTIDADE: 1, //por enquanto vai ficar fixo,
        IDENTIDADE: docs.rg, 
        ORGAO_EMISSOR: 'SSP', //tenho somente o codigo do census, verificar necessidade de mexer na query,
        SIGLA_UF: servidor.estado_naturalidade,
        PIS_PASEP: servidor.pis_pensao, // necessario ver esse campo
        TITULO_ELEITOR: docs.titulo_eleitor,
        EMAIL: servidor.email,
        COD_MUNICIPIO: '329', // codigo dos municipios estão predefinido pelo tribunal de contas, verificar como puxar essa informação,
        ENDER_LOGRADOURO: servidor.ender_logradouro,
        ENDER_NR: end.numero,
        ENDER_COMPL: end.complemento,
        ENDER_BAIRRO: end.bairro,
        ENDER_CEP: end.cep,
        beneficio: this.beneficios.map((beneficio: any) => ({
          ID_GENERO_BENEFICIO: beneficio.genero_beneficio,
          ID_ESPECIE_BENEFICIO: beneficio.especie,
          ID_MODALIDADE_BENEFICIO: beneficio.modalidade,
          MATRICULA: beneficio.matricula,
          IDENTIFICACAO_FUNCIONAL: beneficio.identificacao_funcional,
          CARGO: beneficio.cargo,
          REGIME_JURIDICO: beneficio.regime_juridico,
          CARGA_HORARIA: beneficio.carga_horaria,
          PADRAO: beneficio.padrao,
          NIVEL: beneficio.nivel,
          CLASSE: beneficio.classe,
          OUTROS: beneficio.outros,
          ESCOLARIDADE: beneficio.escolaridade,
          OBSERVACOES: beneficio.observacoes,
          'orgao-concessor': {
            CD_ORGAO: beneficio.codigo,
            CPF_RESPONSAVEL: beneficio.cpf_responsavel,
            CPF_CONTROLE_INTERNO: beneficio.cpf_signatario
          },
          'ultima-remuneracao': {
            TP_LEGISLACAO_ULT_AUMENTO: beneficio.tipo_legislacao,
            NR_LEGISLACAO_ULT_AUMENTO: beneficio.numero_legislacao,
            ANO_LEGISLACAO_ULT_AUMENTO: beneficio.ano_legislacao,
            DT_INICIO_VIGENCIA_TABELA: beneficio.data_inicio,
            'parcela': 
            beneficio.remuneracoes.map((remuneracao: any) => ({
              DS_PARCELA: remuneracao.descricao,
              VALOR: remuneracao.valor,
              TP_LEGISLACAO: remuneracao.tipo_legislacao,
              NR_LEGISLACAO: remuneracao.numero_legislacao,
              ANO_LEGISLACAO: remuneracao.ano_legislacao,
              ARTIGOS_LEGISLACAO: remuneracao.artigos,
            }))                     
          },
          assentamento: beneficio.assentamentos.map((assentamento: any) => ({
            CD_TIPO_ASSENTAMENTO: assentamento.tipo_assentamento,
            DT_ASSENTAMENTO: assentamento.data,
            TP_LEGISLACAO: assentamento.tipo_legislacao,
            NR_LEGISLACAO: assentamento.numero_legislacao,
            ANO_LEGISLACAO: assentamento.ano_legislacao,
            NR_ATO: assentamento.numero_ato,
            DT_PUBLICACAO: assentamento.data_publicacao,
            DS_ASSENTAMENTO: assentamento.descricao,
            NR_DIAS: assentamento.numero_dias,
            DIAS_CONVERTIDOS_LP_DOBRO: assentamento.dias_convertidos_em_dobro,
          })),
          magisterio: beneficio.exerciciosMagisterio.map((magisterio: any) => ({
            DS_ORGAO: magisterio.ds_orgao,
            DT_INICIO: magisterio.dt_inicio,
            DT_FIM: magisterio.dt_fim,
            CD_ORIGEM_ESCOLA: magisterio.cd_origem_escola,
            CD_TIPO_ATIVIDADE_MAGISTERIO: magisterio.cd_tipo_atividade_magisterio,
            DS_ATIVIDADE: magisterio.ds_atividade,
          })),
          voluntaria: {
            DT_REQUERIMENTO: beneficio.dt_requerimento,
            FUNDAMENTO_APOSENTADORIA_OK: beneficio.fundamento_aposentadoria_ok
          },
          averbacao: beneficio.averbacoes.map((averbacao: any) => ({
            DS_ORGAO: averbacao.descricao,
            DT_INICIO: averbacao.data_inicio,
            DT_FIM: averbacao.data_fim,
            DIAS_AVERBADOS: averbacao.dias_averbados,
            NR_DOCUMENTO: averbacao.numero_documento,
            SERVICO_PUBLICO: averbacao.servico_publico,
            CARREIRA: averbacao.carreira,
            VANTAGENS: averbacao.vantagens,
            CONTRIB_INSS: averbacao.contribuicao_inss,
          })),
          media: this.getMediaSalarialFormatted(beneficio),
          ato: {
            TP_ATO: beneficio.tipo_ato,
            NR_ATO: beneficio.numero_ato,
            ANO_ATO: beneficio.ano_ato,
            VALOR_PROVENTOS: beneficio.valor_provento,
            COMPL_SALARIO_MIN: beneficio.complemento_salarial,
            VANTAGENS: beneficio.vantagens,
            DS_ORGAO_CUSTEADOR: beneficio.descricao_orgao
          }         
        })),
      })),
    },
  };

  // Converte para XML
  const xml = create(xmlObject).end({ pretty: true });
  return xml;
}

downloadXML(xmlContent: string, fileName: string): void {
  const blob = new Blob([xmlContent], { type: 'application/xml' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
}

onGenerateXML(): void {
  const self = this

  if(this.beneficios.length == 0){
    this.app.alert('Atenção!', 'Não existem benefícios cadastrados para este servidor.', 'warning');
    return;
  }else if (!this.beneficios.every((benefit) => benefit.isValid) || !this.allDataValid) {
    this.app.alert('Atenção!', 'Existem benefícios inválidos ou dados faltantes no cadastro. Corrija-os antes de gerar o XML.', 'warning');
    return;
  }

  console.log(this.servidor)

  const xmlContent = this.generateXML(this.servidor);
  this.downloadXML(xmlContent, 'sapiem.xml');
}

getMediaSalarialFormatted(beneficio: any) {
  // Obtém os movimentos mensais
  let movimentosMensais = this.movimentosMensais;

  if (movimentosMensais && movimentosMensais.length > 0) {
      console.log('Movimentos mensais:', movimentosMensais);

      // Ordena os movimentos pela data
      movimentosMensais.sort((a: any, b: any) => new Date(a.data).getTime() - new Date(b.data).getTime());

      // Obtém o último movimento para referência
      const ultimoMovimento = movimentosMensais[movimentosMensais.length - 1];
      const ultimaData = new Date(ultimoMovimento.data);

      // Determina o mês e ano de referência para a tag <media>
      const mesReferencia = (ultimaData.getMonth() + 2) > 12 ? 1 : ultimaData.getMonth() + 2;
      const anoReferencia = (ultimaData.getMonth() + 2) > 12 ? ultimaData.getFullYear() + 1 : ultimaData.getFullYear();

      // Cria as linhas para a tag <linha>
      const linhas = movimentosMensais.map((movimento: any) => {
          const dataMovimento = new Date(movimento.data);
          return {
              '@mes': dataMovimento.getMonth() + 1, // Mês do movimento
              '@ano': dataMovimento.getFullYear(), // Ano do movimento
              '@valor-historico': movimento.valor.toFixed(2), // Valor histórico formatado com 2 casas decimais
          };
      });

      // Constrói o objeto no formato esperado
      const mediaObject = {
            linha: linhas,
             '@mes-referencia': mesReferencia,
             '@ano-referencia': anoReferencia,
              DT_DEMONSTRATIVO: beneficio.data_demonstrativo, // Data do demonstrativo
              ASSINADO_POR: beneficio.assinado_por, // Nome do responsável
          
      };

      console.log('Objeto formatado para XML:', mediaObject);

      return mediaObject;
  } else {
      console.warn('Nenhum movimento mensal encontrado.');
      return null;
  }
}


async onGenerateXMLById(idServidor: number, idCidade?: number): Promise<void> {

  await this.spinner.show(undefined, {
    type: 'ball-triangle-path',
    size: 'medium',
    bdColor: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
    fullScreen: true,
  });

  try {
    // Carregar os dados passando o id do servidor recebido
    await this.loadData(idServidor, idCidade);

    // Validar os dados carregados
    this.validateData();

    if (this.beneficios.length === 0) {
      this.spinner.hide();
      this.app.alert('Atenção!', 'Não existem benefícios cadastrados para este servidor.', 'warning');
      return;
    }

    if (!this.beneficios.every((benefit) => benefit.isValid) || !this.allDataValid) {
      this.spinner.hide();
      this.app.alert('Atenção!', 'Existem benefícios inválidos ou dados faltantes no cadastro. Corrija-os antes de gerar o XML.', 'warning');
      return;
    }

    // Gerar o XML
    const xmlContent = this.generateXML(this.servidor);

    // Realizar o download do XML
    this.downloadXML(xmlContent, `servidor_${idServidor}.xml`);
    this.spinner.hide();
    this.app.alert('Sucesso!', 'XML gerado e baixado com sucesso.', 'success');
  } catch (error) {
    this.spinner.hide();
    console.error('Erro ao gerar o XML:', error);
    this.app.alert('Erro!', 'Ocorreu um erro ao gerar o XML. Tente novamente mais tarde.', 'error');
  }
}

validateData() {
  this.missingFields = []; // Resetar campos faltantes

  // Função auxiliar para verificar valores inválidos
  const isInvalid = (value: any) => !value || value === 'null';

  // Verifica os campos de servidor
  if (isInvalid(this.servidor.nome)) this.missingFields.push('Nome Completo');
  if (isInvalid(this.documentos.cpf)) this.missingFields.push('CPF');
  if (isInvalid(this.documentos.rg)) this.missingFields.push('RG');
  if (isInvalid(this.documentos.data_emissao_rg)) this.missingFields.push('Emissão RG');
  if (isInvalid(this.documentos.uf_rg)) this.missingFields.push('Órgão Emissor');
  if (isInvalid(this.servidor.sexo)) this.missingFields.push('Sexo');
  if (isInvalid(this.servidor.data_nascimento)) this.missingFields.push('Nascimento');
  if (isInvalid(this.servidor.nome_pai)) this.missingFields.push('Nome do Pai');
  if (isInvalid(this.servidor.nome_mae)) this.missingFields.push('Nome da Mãe');
  if (isInvalid(this.servidor.estado_civil)) this.missingFields.push('Estado Civil');
  if (isInvalid(this.documentos.nit)) this.missingFields.push('PIS');
  if (isInvalid(this.documentos.titulo_eleitor)) this.missingFields.push('Título Eleitor');
  if (isInvalid(this.contatos.email)) this.missingFields.push('E-mail');

  // Verifica os campos de endereço
  if (isInvalid(this.endereco.cidade)) this.missingFields.push('Cidade');
  if (isInvalid(this.endereco.logradouro)) this.missingFields.push('Endereço');
  if (isInvalid(this.endereco.numero)) this.missingFields.push('Número');
  if (isInvalid(this.endereco.bairro)) this.missingFields.push('Bairro');
  if (isInvalid(this.endereco.cep)) this.missingFields.push('CEP');

  // Verifica se todos os dados estão completos
  this.allDataValid = this.missingFields.length === 0;
}





}
