import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Api from 'app/helpers/api';
import App from 'app/helpers/app';
import Me from 'app/helpers/me';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { create } from 'xmlbuilder';

@Component({
  selector: 'app-sapiem-beneficios',
  templateUrl: './sapiem-beneficios.component.html',
  styleUrls: ['./sapiem-beneficios.component.scss']
})
export class SapiemBeneficiosComponent implements OnInit {
  informacoesBeneficioForm: FormGroup;  // Definimos o FormGroup
  apiCall = this.api.new().silent();
  idServidor: any;
  servidor: any = {};
  documentos: any = {};
  endereco: any = {};
  idCidade: any;
  isVisible: boolean = true;
  isBenefitVisible: boolean = true;
  allDataValid: boolean = false;
  missingFields: string[] = []; // Armazena os campos faltantes
  beneficios: any;
  beneficio = {}
  generoBeneficio = []
  @ViewChild('cadastroBeneficio') cadastroBeneficioModal: TemplateRef<any>;
  especieBeneficio = [];
  modalidadeBeneficio = [];
  regimeJuridico = [];
  tipoLegislacao = [];
  tipoEscolha = [];
  allBenefitsValid: boolean = false;
  movimentosMensais: any;
  contatos: any;
  page: number = 1;
pageSize: number = 10;
servidoresFiltered: any[] = []; // Lista filtrada

estados_civis = [
  { code: 1, description: "SOLTEIRO (A)" },
  { code: 2, description: "CASADO (A)" },
  { code: 3, description: "VIÚVO (A)" },
  { code: 4, description: "SEPARADO (A) JUDICIALMENTE" },
  { code: 5, description: "DIVORCIADO (A)" },
  { code: 6, description: "UNIÃO ESTÁVEL" },
  { code: 9, description: "OUTROS" }
];


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api,
  ) {}

  async ngOnInit() {

    const self = this
   await this.spinner.show(undefined, {
      type: 'ball-triangle-path',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
      fullScreen: true
    });

    // Inicializar o FormGroup com todos os campos do seu formulário
    this.informacoesBeneficioForm = this.fb.group({
      // Informações Básicas
      generoBeneficio: ['', Validators.required],
      especieBeneficio: ['', Validators.required],
      modalidadeBeneficio: ['', Validators.required],
      matricula: ['', Validators.required],
      identificacaoFuncional: ['', Validators.required],
      cargo: ['', Validators.required],
      regimeJuridico: ['', Validators.required],
      cargaHoraria: ['', Validators.required],
      padrao: ['', Validators.required],
      nivel: ['', Validators.required],
      classe: ['', Validators.required],
      outros: [''],
      escolaridade: ['', Validators.required],
      observacoes: [''],
      tituloEleitor: ['', Validators.required],
    
      // Informações Legislativas
      codigoLegislativo: ['', Validators.required],
      cpfResponsavel: ['', Validators.required],
      cpfSignatario: ['', Validators.required],
      tipoLegislacao: ['', Validators.required],
      numeroLegislacao: ['', Validators.required],
      anoLegislacao: ['', Validators.required],
      dataInicio: ['', Validators.required],
    
      // Informações Salariais
      mediaSalarial: ['', Validators.required],
      dataDemonstrativo: ['', Validators.required],
      assinadoPor: ['', Validators.required],
    
      // Informações Judiciais
      tipoAto: ['', Validators.required],
      numeroAto: ['', Validators.required],
      anoAto: ['', Validators.required],
      valorProvento: ['', Validators.required],
      vantagens: [''],
      descricaoOrgao: ['', Validators.required],
      complementoSalarial: ['', Validators.required]
    });
    

    this.route.queryParams.subscribe(parametros => {
      if (parametros['id']) {
        this.idServidor = parametros['id'];
      }
      if (parametros['id_cidade']) {
        this.idCidade = parametros['id_cidade'];
      }
    });
    await this.loadReferencesData();
    await this.loadData();
    this.validateData();
    this.popularFormularioComDados()
    await this.getMediaSalarial()

    this.spinner.hide();
  }

  async loadData() {
    const self = this;
    try {
      const servidor = await this.apiCall.get(`servidor?id=${this.idServidor}&id_cidade=${this.idCidade}`).pipe(take(1)).toPromise();
      if (servidor) this.servidor = servidor;

      const endereco = await this.apiCall.get(`servidor/endereco?id_servidor=${this.idServidor}`).pipe(take(1)).toPromise();
      if (endereco) this.endereco = endereco;

      const documento = await this.apiCall.get(`servidor/documentos?id_servidor=${this.idServidor}`).pipe(take(1)).toPromise();
      if (documento) this.documentos = documento;
      console.log('Documentos:', documento);

      const contatos = await this.apiCall.get(`servidor/contatos?id_servidor=${this.idServidor}`).pipe(take(1)).toPromise();
      if (contatos) this.contatos = contatos;

      let beneficios = await this.apiCall.get(`sapiem/beneficio/${this.idServidor}`).pipe(take(1)).toPromise();
      if (beneficios) {
        this.beneficios = await this.loadAdditionalDataForBeneficios(beneficios);

        this.beneficios = this.beneficios.map((benefit: any) => ({
          ...benefit,
          isValid: this.validateBenefit(benefit),
        }));

        this.allBenefitsValid = this.beneficios.every((benefit: any) => benefit.isValid);

      }

      console.log("beneficios", this.beneficios)

    } catch (error) {
      console.error('Erro ao carregar os dados do servidor:', error);
    }
  }


  private async loadAdditionalDataForBeneficios(
    beneficios: any[]
  ): Promise<any[]> {
    // Itera sobre cada benefício e adiciona os dados adicionais
    const beneficiosCompletos = await Promise.all(
      beneficios.map(async (beneficio: any) => {
        const idBeneficio = beneficio.id;
  
        try {
          // Busca os dados adicionais para o benefício
          const [assentamentos, exerciciosMagisterio, averbacoes, remuneracoes] =
            await Promise.all([
              this.apiCall
                .get(`sapiem/assentamento/${idBeneficio}`)
                .pipe(take(1))
                .toPromise()
                .catch(() => []), // Retorna array vazio em caso de erro
              this.apiCall
                .get(`sapiem/magisterio/${idBeneficio}`)
                .pipe(take(1))
                .toPromise()
                .catch(() => []),
              this.apiCall
                .get(`sapiem/averbacao/${idBeneficio}`)
                .pipe(take(1))
                .toPromise()
                .catch(() => []),
              this.apiCall
                .get(`sapiem/remuneracao/${idBeneficio}`)
                .pipe(take(1))
                .toPromise()
                .catch(() => []),
            ]);
  
          // Retorna o benefício com os dados adicionais
          return {
            ...beneficio,
            assentamentos: assentamentos || [],
            exerciciosMagisterio: exerciciosMagisterio || [],
            averbacoes: averbacoes || [],
            remuneracoes: remuneracoes || [],
          };
        } catch (error) {
          console.error(`Erro ao carregar dados adicionais para o benefício ${idBeneficio}:`, error);
  
          // Retorna o benefício original mesmo em caso de erro
          return {
            ...beneficio,
            assentamentos: [],
            exerciciosMagisterio: [],
            averbacoes: [],
            remuneracoes: [],
          };
        }
      })
    );
    console.log('Benefícios completos:', beneficiosCompletos);
    return beneficiosCompletos;
  }

  getGeneroBeneficioNome(id) {
    let label = this.generoBeneficio.find(genero => genero.id == id)
    if(label){
      return label.nome;
    }else{
      return '';
    }
  }

  getEspecieBeneficioNome(id) {
    let label = this.especieBeneficio.find(especie => especie.id == id)
    if(label){
      return label.nome;
    }else{
      return '';
    }
  }

  getLegislacaoNome(id) {
    let label = this.tipoLegislacao.find(tipo => tipo.id == id)
    if(label){
      return label.nome;
    }else{
      return '';
    }
  }

  getModalidadeBeneficioNome(id) {
    let label = this.modalidadeBeneficio.find(tipo => tipo.id == id)
    if(label){
      return label.nome;
    }else{
      return '';
    }
  }



  

  async loadReferencesData() {
    const self = this;

    try{

      const generoBeneficio = await this.apiCall.get('sapiem/getGeneroBeneficio').pipe(take(1)).toPromise();
      if (generoBeneficio) this.generoBeneficio = generoBeneficio;

      console.log('Genero beneficio:', generoBeneficio);

      const especieBeneficio = await this.apiCall.get('sapiem/getEspecie').pipe(take(1)).toPromise();
      if (especieBeneficio) this.especieBeneficio = especieBeneficio;

      const modalidadeBeneficio = await this.apiCall.get('sapiem/getMobilidade').pipe(take(1)).toPromise();
      if (modalidadeBeneficio) this.modalidadeBeneficio = modalidadeBeneficio;

      const regimeJuridico = await this.apiCall.get('sapiem/getRegimeJuridico').pipe(take(1)).toPromise();
      if (regimeJuridico) this.regimeJuridico = regimeJuridico;

      const tipoLegislacao = await this.apiCall.get('sapiem/getTipoLegislacao').pipe(take(1)).toPromise();
      if (tipoLegislacao) this.tipoLegislacao = tipoLegislacao;

      const sn = await this.apiCall.get('sapiem/getSn').pipe(take(1)).toPromise();
      if (sn) this.tipoEscolha = sn;


    }catch(error){
      console.error('Erro ao carregar os dados de referência:', error);
    }


  }

  toggleInfo() {
    this.isVisible = !this.isVisible;
  }

  toggleBenefit() {
    this.isBenefitVisible = !this.isBenefitVisible;
  }

  validateData() {
    this.missingFields = []; // Resetar campos faltantes
  
    // Função auxiliar para verificar valores inválidos
    const isInvalid = (value: any) => !value || value === 'null';
  
    // Verifica os campos de servidor
    if (isInvalid(this.servidor.nome)) this.missingFields.push('Nome Completo');
    if (isInvalid(this.documentos.cpf)) this.missingFields.push('CPF');
    if (isInvalid(this.documentos.rg)) this.missingFields.push('RG');
    if (isInvalid(this.documentos.data_emissao_rg)) this.missingFields.push('Emissão RG');
    if (isInvalid(this.documentos.uf_rg)) this.missingFields.push('Órgão Emissor');
    if (isInvalid(this.servidor.sexo)) this.missingFields.push('Sexo');
    if (isInvalid(this.servidor.data_nascimento)) this.missingFields.push('Nascimento');
    if (isInvalid(this.servidor.nome_pai)) this.missingFields.push('Nome do Pai');
    if (isInvalid(this.servidor.nome_mae)) this.missingFields.push('Nome da Mãe');
    if (isInvalid(this.servidor.estado_civil)) this.missingFields.push('Estado Civil');
    if (isInvalid(this.documentos.nit)) this.missingFields.push('PIS');
    if (isInvalid(this.documentos.titulo_eleitor)) this.missingFields.push('Título Eleitor');
    if (isInvalid(this.contatos.email)) this.missingFields.push('E-mail');
  
    // Verifica os campos de endereço
    if (isInvalid(this.endereco.cidade)) this.missingFields.push('Cidade');
    if (isInvalid(this.endereco.logradouro)) this.missingFields.push('Endereço');
    if (isInvalid(this.endereco.numero)) this.missingFields.push('Número');
    if (isInvalid(this.endereco.bairro)) this.missingFields.push('Bairro');
    if (isInvalid(this.endereco.cep)) this.missingFields.push('CEP');
  
    // Verifica se todos os dados estão completos
    this.allDataValid = this.missingFields.length === 0;
  }
  

  addBeneficio(){
    alert('Adicionar benefício');
  }

  editBeneficio(id){

    this.router.navigate(['page/sapiem-beneficios-detalhe'], { queryParams: { idBeneficio: id, nomeServidor: this.servidor.nome } });

  }

  async deleteBeneficio(id) {
    const self = this;
    
    if (
      await self.app.confirm(
        `Atenção`,
        `Você deseja mesmo deletar este benefício?`,
        `Deletar`
      )
    ) {
      self.spinner.show(undefined, {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
  
      self.apiCall.delete(`sapiem/beneficio/${id}`).pipe(take(1)).subscribe(
        (data: any) => {
          self.spinner.hide();
          
          if (data && data.message=="Benefício deletado com sucesso") {
            self.app.alert('Sucesso', 'Benefício excluído com sucesso!', 'success');
            self.loadData(); 
          } else {
            self.app.alert('Erro', 'Não foi possível excluir o benefício. Tente novamente.', 'error');
          }
        },
        (error) => {
          self.spinner.hide();
          self.app.alert('Erro', 'Erro ao excluir benefício!', 'error');
          console.error('Erro ao excluir benefício:', error);
        }
      );
    }
  }

  openCadastroBeneficio() {
    this.informacoesBeneficioForm.reset();
    this.modalService.open(this.cadastroBeneficioModal, { size: 'xl' });
  }

 async onSubmitBaseBenefit(){

    const self = this;

      self.spinner.show(undefined, {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    const formValues = this.informacoesBeneficioForm.value;

// Crie um objeto de benefício a partir dos valores do formulário, ajustando os nomes

const beneficio = this.setBenefitToSend(formValues);

self.apiCall.post(`sapiem/createBeneficio/`, beneficio).pipe(take(1)).subscribe(
  async (data: any) => {
    self.spinner.hide();
    
    if (data && data.id) {
      self.modalService.dismissAll();
      await self.app.alert('Sucesso', 'Benefício cadastrado com sucesso!', 'success');
      this.router.navigate(['page/sapiem-beneficios-detalhe'], { queryParams: { idBeneficio: data.id, nomeServidor: this.servidor.nome } });
      self.loadData(); 
    } else {
      self.app.alert('Erro', 'Não foi possível excluir o benefício. Tente novamente.', 'error');
    }
  },
  (error) => {
    self.spinner.hide();
    self.app.alert('Erro', 'Erro ao excluir benefício!', 'error');
    console.error('Erro ao excluir benefício:', error);
  }
);



// Agora você pode enviar o objeto `beneficio` para o backend
console.log('Objeto benefício pronto para envio:', beneficio);


  }

  setBenefitToSend(formValues) {

    return {
        genero_beneficio: formValues.generoBeneficio,
        especie: formValues.especieBeneficio,
        modalidade: formValues.modalidadeBeneficio, 
        matricula: formValues.matricula,
        identificacao_funcional: formValues.identificacaoFuncional,
        cargo: formValues.cargo,
        regime_juridico: formValues.regimeJuridico,
        carga_horaria: formValues.cargaHoraria,
        padrao: formValues.padrao,
        nivel: formValues.nivel,
        classe: formValues.classe,
        outros: formValues.outros,
        escolaridade: formValues.escolaridade,
        observacoes: formValues.observacoes,
        id_servidor: this.idServidor,
        codigo: formValues.codigoLegislativo, 
        cpf_responsavel: formValues.cpfResponsavel, 
        cpf_signatario: formValues.cpfSignatario, 
        tipo_legislacao: formValues.tipoLegislacao, 
        numero_legislacao: formValues.numeroLegislacao, 
        ano_legislacao: formValues.anoLegislacao, 
        data_inicio: formValues.dataInicio, 
        tipo_ato: formValues.tipoAto, 
        numero_ato: formValues.numeroAto, 
        ano_ato: formValues.anoAto, 
        valor_provento: formValues.valorProvento, 
        complemento_salarial: formValues.complementoSalarial, 
        vantagens: formValues.vantagens, 
        descricao_orgao_custeador: formValues.descricaoOrgao, 
        media_salarial: formValues.mediaSalarial, 
        data_demonstrativo: formValues.dataDemonstrativo, 
        assinado_por: formValues.assinadoPor,
      };
    }



  popularFormularioComDados(): void {
    const dados = {
      genero_beneficio: 21,
      especie: 21,
      modalidade: 1,
      matricula: 12345,
      identificacao_funcional: "1234",
      cargo: "1235",
      regime_juridico: "C",
      carga_horaria: 140,
      padrao: "teste",
      nivel: "teste",
      classe: "teste",
      outros: "teste",
      codigo: 123,
      escolaridade: "teste",
      observacoes: "teste",
      id_servidor: "34758",
      cpf_responsavel: "08599606417",
      cpf_signatario: "08599606417",
      tipo_legislacao: "ACO",
      numero_legislacao: "123",
      ano_legislacao: "1994",
      data_inicio: "1994-01-01",
      data_demonstrativo: "2024-01-01",
      tipo_ato: "212",
      numero_ato: "212",
      ano_ato: "2024",
      valor_provento: "154",
      vantagens: "12",
      descricao_orgao_custeador: "12",
      media_salarial: "1234",
      assinado_por: "JOCEL",
      complemento_salarial: "S"
    };

    // Usamos patchValue para popular o formulário com os dados
    this.informacoesBeneficioForm.patchValue({
      generoBeneficio: dados.genero_beneficio,
      especieBeneficio: dados.especie,
      modalidadeBeneficio: dados.modalidade,
      matricula: dados.matricula,
      identificacaoFuncional: dados.identificacao_funcional,
      cargo: dados.cargo,
      regimeJuridico: dados.regime_juridico,
      cargaHoraria: dados.carga_horaria,
      padrao: dados.padrao,
      nivel: dados.nivel,
      classe: dados.classe,
      outros: dados.outros,
      escolaridade: dados.escolaridade,
      observacoes: dados.observacoes,
      cpfResponsavel: dados.cpf_responsavel,
      codigoLegislativo: dados.codigo,
      cpfSignatario: dados.cpf_signatario,
      tipoLegislacao: dados.tipo_legislacao,
      numeroLegislacao: dados.numero_legislacao,
      anoLegislacao: dados.ano_legislacao,
      dataDemonstrativo: dados.data_demonstrativo,
      dataInicio: dados.data_inicio,
      tipoAto: dados.tipo_ato,
      numeroAto: dados.numero_ato,
      anoAto: dados.ano_ato,
      valorProvento: dados.valor_provento,
      vantagens: dados.vantagens,
      descricaoOrgao: dados.descricao_orgao_custeador,
      mediaSalarial: dados.media_salarial,
      assinadoPor: dados.assinado_por,
      complementoSalarial: dados.complemento_salarial
    });
  }

  async getMediaSalarial() {
    const self = this
    let movimentosMensais = await this.apiCall.get('servidor/movimentos/movimentacoesmensais/recuperar?id_servidor='+self.idServidor).pipe(take(1)).toPromise()
    
        if (movimentosMensais) {
          console.log('Movimentos mensais:', movimentosMensais);

          this.movimentosMensais = movimentosMensais
          // Calcula a média do campo 'valor'
          const total = movimentosMensais.reduce((sum, item) => sum + item.valor, 0);
          const media = total / movimentosMensais.length;
          
          // Atualiza o campo mediaSalarial no formulário
          this.informacoesBeneficioForm.get('mediaSalarial').setValue(media.toFixed(2));
          
          console.log('Média calculada e atualizada:', media);
      }
  }  

  async importar(){
    const self = this
    const { value: file } = await Swal.fire({
      title: 'Selecione a planilha para importar',
      input: 'file',
      inputAttributes: {
        'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        'aria-label': 'Selecione a planilha'
      },
    })

    if(!file){
      await this.app.alert('Atenção!',  'Você deve informar uma planilha!', 'error')
      return
    }

    if(!(file as any).name.endsWith('xlsx')){
      await this.app.alert('Atenção!',  'Você deve informar planilhas no formato .xlsx!', 'error')
      return
    }
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file as any);
    fileReader.onload = async (e) => {
      const formData = new FormData();
      formData.append('file', file as any);
      try{
        self.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });

        await this.apiCall.post('servidor/arquivo/servidores/salario?servidor='+self.idServidor, formData).pipe(take(1)).toPromise()
        await self.getMediaSalarial()
        self.spinner.hide()
        await this.app.alert(
          "Tudo certo!",
            "Movimento Mensal importado com sucesso!",
          "success"
        );
      
      }catch(e){
        await this.app.alert(
          "Ops!",
            "Ocorreu um erro com a importação, contate o suporte!",
          "warning"
        );
      }
    }

  }

// Método para validar um benefício
validateBenefit(benefit: any): boolean {
  const { especie, assentamentos, remuneracoes, averbacoes, exerciciosMagisterio } = benefit;

  if (!especie) {
    console.error("Benefício sem espécie identificada:", benefit);
    return false;
  }

  // Validações para benefícios que não são da espécie 22
  const hasValidAssentamento = assentamentos?.some((item: any) =>
    item && Object.values(item).every((value) => value !== null && value !== undefined && value !== '')
  );

  const hasValidRemuneracao = remuneracoes?.some((item: any) =>
    item && Object.values(item).every((value) => value !== null && value !== undefined && value !== '')
  );

  const hasValidAverbacao = averbacoes?.some((item: any) =>
    item && Object.values(item).every((value) => value !== null && value !== undefined && value !== '')
  );

  if (especie !== 22) {
    return hasValidAssentamento && hasValidRemuneracao && hasValidAverbacao;
  }

  // Validações adicionais para benefícios da espécie 22
  const hasValidMagisterio = exerciciosMagisterio?.some((item: any) =>
    item && Object.values(item).every((value) => value !== null && value !== undefined && value !== '')
  );

  return hasValidAssentamento && hasValidRemuneracao && hasValidAverbacao && hasValidMagisterio;
}

getMediaSalarialFormatted(beneficio: any) {
  // Obtém os movimentos mensais
  let movimentosMensais = this.movimentosMensais;

  if (movimentosMensais && movimentosMensais.length > 0) {
      console.log('Movimentos mensais:', movimentosMensais);

      // Ordena os movimentos pela data
      movimentosMensais.sort((a: any, b: any) => new Date(a.data).getTime() - new Date(b.data).getTime());

      // Obtém o último movimento para referência
      const ultimoMovimento = movimentosMensais[movimentosMensais.length - 1];
      const ultimaData = new Date(ultimoMovimento.data);

      // Determina o mês e ano de referência para a tag <media>
      const mesReferencia = (ultimaData.getMonth() + 2) > 12 ? 1 : ultimaData.getMonth() + 2;
      const anoReferencia = (ultimaData.getMonth() + 2) > 12 ? ultimaData.getFullYear() + 1 : ultimaData.getFullYear();

      // Cria as linhas para a tag <linha>
      const linhas = movimentosMensais.map((movimento: any) => {
          const dataMovimento = new Date(movimento.data);
          return {
              '@mes': dataMovimento.getMonth() + 1, // Mês do movimento
              '@ano': dataMovimento.getFullYear(), // Ano do movimento
              '@valor-historico': movimento.valor.toFixed(2), // Valor histórico formatado com 2 casas decimais
          };
      });

      // Constrói o objeto no formato esperado
      const mediaObject = {
            linha: linhas,
             '@mes-referencia': mesReferencia,
             '@ano-referencia': anoReferencia,
              DT_DEMONSTRATIVO: beneficio.data_demonstrativo, // Data do demonstrativo
              ASSINADO_POR: beneficio.assinado_por, // Nome do responsável
          
      };

      console.log('Objeto formatado para XML:', mediaObject);

      return mediaObject;
  } else {
      console.warn('Nenhum movimento mensal encontrado.');
      return null;
  }
}



generateXML(data: any): string {
  console.log('Gerando XML com os dados:', data);

  const normalizedData = Array.isArray(data) ? data : [data];
  const docs = this.documentos;
  const end = this.endereco;

  console.log(this.beneficios)

  const xmlObject = {
    sapiem: {
      servidor: normalizedData.map((servidor: any) => ({
        NOME: servidor.nome,
        CPF: servidor.cpf,
        SEXO: servidor.sexo,
        NACIONALIDADE: servidor.nacionalidade,
        DT_NASCIMENTO: servidor.dt_nascimento,
        NM_MAE: servidor.nome_mae,
        NM_PAI: servidor.nome_pai,
        ESTADO_CIVIL: servidor.estado_civil, 
        OUTROS_NOMES: servidor.nome,
        TIPO_IDENTIDADE: 1, //por enquanto vai ficar fixo,
        IDENTIDADE: docs.rg, 
        ORGAO_EMISSOR: 'SSP', //tenho somente o codigo do census, verificar necessidade de mexer na query,
        SIGLA_UF: servidor.estado_naturalidade,
        PIS_PASEP: servidor.pis_pensao, // necessario ver esse campo
        TITULO_ELEITOR: docs.titulo_eleitor,
        EMAIL: servidor.email,
        COD_MUNICIPIO: '329', // codigo dos municipios estão predefinido pelo tribunal de contas, verificar como puxar essa informação,
        ENDER_LOGRADOURO: servidor.ender_logradouro,
        ENDER_NR: end.numero,
        ENDER_COMPL: end.complemento,
        ENDER_BAIRRO: end.bairro,
        ENDER_CEP: end.cep,
        beneficio: this.beneficios.map((beneficio: any) => ({
          ID_GENERO_BENEFICIO: beneficio.genero_beneficio,
          ID_ESPECIE_BENEFICIO: beneficio.especie,
          ID_MODALIDADE_BENEFICIO: beneficio.modalidade,
          MATRICULA: beneficio.matricula,
          IDENTIFICACAO_FUNCIONAL: beneficio.identificacao_funcional,
          CARGO: beneficio.cargo,
          REGIME_JURIDICO: beneficio.regime_juridico,
          CARGA_HORARIA: beneficio.carga_horaria,
          PADRAO: beneficio.padrao,
          NIVEL: beneficio.nivel,
          CLASSE: beneficio.classe,
          OUTROS: beneficio.outros,
          ESCOLARIDADE: beneficio.escolaridade,
          OBSERVACOES: beneficio.observacoes,
          'orgao-concessor': {
            CD_ORGAO: beneficio.codigo,
            CPF_RESPONSAVEL: beneficio.cpf_responsavel,
            CPF_CONTROLE_INTERNO: beneficio.cpf_signatario
          },
          'ultima-remuneracao': {
            TP_LEGISLACAO_ULT_AUMENTO: beneficio.tipo_legislacao,
            NR_LEGISLACAO_ULT_AUMENTO: beneficio.numero_legislacao,
            ANO_LEGISLACAO_ULT_AUMENTO: beneficio.ano_legislacao,
            DT_INICIO_VIGENCIA_TABELA: beneficio.data_inicio,
            'parcela': 
            beneficio.remuneracoes.map((remuneracao: any) => ({
              DS_PARCELA: remuneracao.descricao,
              VALOR: remuneracao.valor,
              TP_LEGISLACAO: remuneracao.tipo_legislacao,
              NR_LEGISLACAO: remuneracao.numero_legislacao,
              ANO_LEGISLACAO: remuneracao.ano_legislacao,
              ARTIGOS_LEGISLACAO: remuneracao.artigos,
            }))                     
          },
          assentamento: beneficio.assentamentos.map((assentamento: any) => ({
            CD_TIPO_ASSENTAMENTO: assentamento.tipo_assentamento,
            DT_ASSENTAMENTO: assentamento.data,
            TP_LEGISLACAO: assentamento.tipo_legislacao,
            NR_LEGISLACAO: assentamento.numero_legislacao,
            ANO_LEGISLACAO: assentamento.ano_legislacao,
            NR_ATO: assentamento.numero_ato,
            DT_PUBLICACAO: assentamento.data_publicacao,
            DS_ASSENTAMENTO: assentamento.descricao,
            NR_DIAS: assentamento.numero_dias,
            DIAS_CONVERTIDOS_LP_DOBRO: assentamento.dias_convertidos_em_dobro,
          })),
          magisterio: beneficio.exerciciosMagisterio.map((magisterio: any) => ({
            DS_ORGAO: magisterio.ds_orgao,
            DT_INICIO: magisterio.dt_inicio,
            DT_FIM: magisterio.dt_fim,
            CD_ORIGEM_ESCOLA: magisterio.cd_origem_escola,
            CD_TIPO_ATIVIDADE_MAGISTERIO: magisterio.cd_tipo_atividade_magisterio,
            DS_ATIVIDADE: magisterio.ds_atividade,
          })),
          voluntaria: {
            DT_REQUERIMENTO: beneficio.dt_requerimento,
            FUNDAMENTO_APOSENTADORIA_OK: beneficio.fundamento_aposentadoria_ok
          },
          averbacao: beneficio.averbacoes.map((averbacao: any) => ({
            DS_ORGAO: averbacao.descricao,
            DT_INICIO: averbacao.data_inicio,
            DT_FIM: averbacao.data_fim,
            DIAS_AVERBADOS: averbacao.dias_averbados,
            NR_DOCUMENTO: averbacao.numero_documento,
            SERVICO_PUBLICO: averbacao.servico_publico,
            CARREIRA: averbacao.carreira,
            VANTAGENS: averbacao.vantagens,
            CONTRIB_INSS: averbacao.contribuicao_inss,
          })),
          media: this.getMediaSalarialFormatted(beneficio),
          ato: {
            TP_ATO: beneficio.tipo_ato,
            NR_ATO: beneficio.numero_ato,
            ANO_ATO: beneficio.ano_ato,
            VALOR_PROVENTOS: beneficio.valor_provento,
            COMPL_SALARIO_MIN: beneficio.complemento_salarial,
            VANTAGENS: beneficio.vantagens,
            DS_ORGAO_CUSTEADOR: beneficio.descricao_orgao
          }         
        })),
      })),
    },
  };

  // Converte para XML
  const xml = create(xmlObject).end({ pretty: true });
  return xml;
}

downloadXML(xmlContent: string, fileName: string): void {
  const blob = new Blob([xmlContent], { type: 'application/xml' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
}

onGenerateXML(): void {
  const self = this

  if(this.beneficios.length == 0){
    this.app.alert('Atenção!', 'Não existem benefícios cadastrados para este servidor.', 'warning');
    return;
  }else if (!this.beneficios.every((benefit) => benefit.isValid) || !this.allDataValid) {
    this.app.alert('Atenção!', 'Existem benefícios inválidos ou dados faltantes no cadastro. Corrija-os antes de gerar o XML.', 'warning');
    return;
  }

  console.log(this.servidor)

  const xmlContent = this.generateXML(this.servidor);
  this.downloadXML(xmlContent, 'sapiem.xml');
}

formatDateByString(date: string): string {
  if (!date || date.length !== 8) {
    return ""; // Retorna vazio se a data for inválida ou não tiver 8 caracteres
  }

  // Extrai o dia, mês e ano da string no formato ddmmyyyy
  const day = parseInt(date.substring(0, 2), 10);
  const month = parseInt(date.substring(2, 4), 10) - 1; // Mês em JavaScript é baseado em zero
  const year = parseInt(date.substring(4, 8), 10);

  // Cria um objeto Date
  const d = new Date(year, month, day);

  // Verifica se a data é válida
  if (isNaN(d.getTime())) {
    return ""; // Retorna vazio se a data for inválida
  }

  // Formata a data no padrão yyyy-MM-dd
  const formattedYear = d.getFullYear();
  const formattedMonth = (d.getMonth() + 1).toString().padStart(2, "0");
  const formattedDay = d.getDate().toString().padStart(2, "0");

  return `${formattedDay}/${formattedMonth}/${formattedYear}`;
}

getEstadoCivil(statusCode: number): string {
  const status = this.estados_civis.find(item => item.code === statusCode);
  return status ? status.description : "DESCONHECIDO"; // Retorna "DESCONHECIDO" se não encontrar
}


  
}
