import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from "../helpers/excel.service";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import { take } from "rxjs/operators";
import { saveAs } from "file-saver";
import * as moment from "moment";

@Component({
  selector: "app-relatorios-admin",
  templateUrl: "./relatorios-admin.component.html",
  styleUrls: ["./relatorios-admin.component.scss"],
})
export class RelatoriosAdminComponent implements OnInit {
  apiCall = this.api.new().silent();
  page = 1;
  pageSize = 10;
  tabelasGerais = null;
  tabelasServidores = null;
  desabilitaRelatorio = false;
  tabelas = null;
  arquivos = null;
  filterAno = null;
  cidades = [];
  servidoresAtivos = [];
  servidoresInativos = [];
  servidoresPensionistas = [];
  cidadeEscolhida = "todas";
  nomeCidade = "todas";
  tipoDeFundo = [
    {
      id: 1,
      valor: "Fundo em Capitalização",
    },
    {
      id: 1,
      valor: "Sem Segregação da Massa",
    },
    {
      id: 2,
      valor: "Fundo em Repartição (em caso de Segregação da Massa)",
    },
    {
      id: 3,
      valor:
        "Beneficiário sob responsabilidade direta do ente federativo ('Mantidos pelo Tesouro')",
    },
  ];

  poder = [
    { id: 1, valor: "Executivo" },
    { id: 2, valor: "Legislativo" },
    { id: 3, valor: "Judiciário" },
    { id: 4, valor: "Ministério Público" },
    { id: 5, valor: "Tribunal de Contas" },
    { id: 6, valor: "Defensoria Pública" },
  ];

  tipoPoder = [
    { id: 1, valor: "Administração Direta" },
    { id: 1, valor: "Administração Indireta" },
  ];

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private me: Me,
    private app: App,
    private api: Api
  ) {}

  async ngOnInit() {
    const self = this;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    this.desabilitaRelatorio = true;
    const tabelas = await self.apiCall
      .get("banco-dados/list")
      .pipe(take(1))
      .toPromise();
    self.tabelas = tabelas;
    self.tabelasServidores = self.tabelas.filter((tabela) =>
      tabela.tabela.includes("servidor")
    );
    self.tabelasGerais = self.tabelas.filter(
      (tabela) => !tabela.tabela.includes("servidor")
    );
    const arquivos = await self.apiCall
      .get("banco-dados/files/list")
      .pipe(take(1))
      .toPromise();
    self.arquivos = arquivos;
    const cidades = await self.apiCall
      .get("banco-dados/cidades/get")
      .pipe(take(1))
      .toPromise();
    self.cidades = cidades;
    this.spinner.hide();
  }
  changeCidade($event) {
    this.nomeCidade = this.cidades.find(
      (x) => x.id == this.cidadeEscolhida
    ).nome;
    if (this.cidadeEscolhida === "todas") {
      this.desabilitaRelatorio = true;
    } else {
      this.desabilitaRelatorio = false;
    }
  }
  async exportarTabelaServidores(tabela) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const dados = await this.apiCall
      .get(
        `banco-dados/servidores/get?tabela=${tabela}&cidade=${this.cidadeEscolhida}`
      )
      .pipe(take(1))
      .toPromise();
    this.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(dados)),
      tabela
    );
    this.spinner.hide();
  }

  async exportarTabelaGeral(tabela) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const dados = await this.apiCall
      .get(`banco-dados/geral/get?tabela=${tabela}`)
      .pipe(take(1))
      .toPromise();
    this.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(dados)),
      tabela
    );
    this.spinner.hide();
  }
  async ziparArquivos() {
    const self = this;
    const dadosUsuario = self.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const idUsuario = json.user.id;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (self.cidadeEscolhida === "todas") {
      const zip = await this.apiCall
        .get(`banco-dados/geral/files/zip?usuario=${idUsuario}`)
        .pipe(take(1))
        .toPromise();
    } else {
      const zip = await this.apiCall
        .get(
          `banco-dados/servidores/files/zip?usuario=${idUsuario}&cidade=${self.cidadeEscolhida}`
        )
        .pipe(take(1))
        .toPromise();
    }
    this.spinner.hide();
    self.ngOnInit();
  }

  async exportarBancoDeDadosPuro() {
    const self = this;
    if (self.cidadeEscolhida !== "todas") {
      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      const retorno = await this.apiCall
        .get(`servidor/relatorio/puro?id_cidade=${self.cidadeEscolhida}`)
        .pipe(take(1))
        .toPromise();
      this.spinner.hide();
      if (retorno && JSON.parse(JSON.stringify(retorno)).location) {
        console.log("abrindo arquivo..");
        window.open(
          JSON.parse(JSON.stringify(retorno)).location.toString(),
          "_blank"
        );
        setTimeout(async function () {
          try {
            console.log("deletando arquivo gerado..");
            await self.apiCall
              .get(
                `servidor/relatorio/puro/delete?key=${JSON.parse(
                  JSON.stringify(retorno)
                ).key.toString()}`
              )
              .pipe(take(1))
              .toPromise();
          } catch (e) {
            console.log(e);
          }
        }, 5000);
      }
    }
  }

  async exportarBancoDeDadosFormatoSPREV() {
    const self = this;

    //here

    if (self.cidadeEscolhida !== "todas") {
      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      const servidores = await this.apiCall
        .get(`servidor/relatorio/brprev?id_cidade=${self.cidadeEscolhida}`)
        .pipe(take(1))
        .toPromise();
      if (!servidores) {
        this.spinner.hide();
        return;
      }

      console.log("servidores", servidores);
      self.servidoresAtivos = [];
      self.servidoresPensionistas = [];
      self.servidoresInativos = [];
      let servidoresPorAno = servidores;
      for (let servidor of servidoresPorAno) {
        //Declarações e formatação de campos

        let tipoFundo = null;
        let poder = null;
        let tipoPoder = null;
        let bPopulacaoCoberta = null;
        let cPopulacaoCoberta = null;

        if (this.tipoDeFundo.find((t) => t.valor == servidor.fundo)) {
          tipoFundo = this.tipoDeFundo.find(
            (t) => t.valor == servidor.fundo
          ).id;
        }

        if (this.poder.find((t) => t.valor == servidor.poder)) {
          poder = this.poder.find((t) => t.valor == servidor.poder).id;
        }

        if (this.tipoPoder.find((t) => t.valor == servidor.tipo_poder)) {
          tipoPoder = this.tipoPoder.find(
            (t) => t.valor == servidor.tipo_poder
          ).id;
        }

        

        if (servidor.status !== null && servidor.status == 1) {
          
          if (servidor.carreira == 6) {
            bPopulacaoCoberta = 2;
          } else {
            bPopulacaoCoberta = 7;
          }
  
          if (servidor.populacao_coberta_c) {
            cPopulacaoCoberta = 3;
            if (servidor.populacao_coberta_c == "Como Professor") {
              cPopulacaoCoberta = 3;
            } else {
              cPopulacaoCoberta = 1;
            }
          }

          let data = {
            ANO: servidor.ano,
            MÊS: servidor.mes,
            CO_IBGE: servidor.ibge,
            NOME_DO_ENTE: servidor.nome_cidade,
            SIGLA_ESTADO: servidor.estado_naturalidade,
            "COMPOSIÇÃO DA MASSA (A)": "1",
            "TIPO DO FUNDO (B)": tipoFundo ? tipoFundo : "",
            CNPJ: servidor.cnpj,
            "NOME DO ÓRGÃO/ENTIDADE": servidor.orgao,
            PODER: poder ? poder : "",
            TIPO: tipoPoder ? tipoPoder : "",
            "A - POPULAÇÃO COBERTA":
              servidor.carreira == "21" ? "Militares em atividade" : "Ativos",
            "B- POPULAÇÃO COBERTA": bPopulacaoCoberta,
            "C - POPULAÇÃO COBERTA": cPopulacaoCoberta,
            Matrícula: servidor.matricula,
            CPF: servidor.cpf,
            "PIS-PASEP": servidor.pis,
            SEXO: servidor.sexo,
            "ESTADO CIVIL": servidor.estadoCivil,
            "DATA DE NASCIMENTO": servidor.dataNascimento,
            "COD SITUAÇÃO FUNCIONAL": servidor.situacao_funcional,
            "TIPO DE VÍNCULO": "1",
            "DATA DE INGRESSO NO SERV_PUBLICO": servidor.data_ingresso_serv,
            "DATA DE INGRESSO NO ENTE": servidor.dataIngressoEnte,
            "DATA DE INGRESSO NA CARREIRA ATUAL":
              servidor.dataIngressoCarreiraAtual,
            "IDENTIFICAÇÃO DA CARREIRA ATUAL": servidor.carreiraAtual,
            "DATA DE INÍCIO DE EXERCÍCIO NO CARGO ATUAL":
              servidor.dataInicioCargoAtual,
            "IDENTIFICAÇÃO DO CARGO ATUAL": servidor.cargoAtual,
            "BASE DE CÁLCULO MENSAL DO SERVIDOR ATIVO":
              servidor.salarioBaseMensal,
            "REMUNERAÇÃO MENSAL TOTAL DO SERVIDOR ATIVO": servidor.salarioTotal,
            "CONTRIBUIÇÃO MENSAL": "",
            "PRIMEIRA BASE DE CÁLCULO MENSAL DO SERVIDOR ATIVO": "",
            "TEMPO DE CONTRIBUIÇÃO DO SERVIDOR ATIVO ANTERIOR À ADMISSÃO NO ENTE PARA O RGPS":
              servidor.tempoServicoAnteriorTotal,
            // "TEMPO DE CONTRIBUIÇÃO DO SERVIDOR ATIVO ANTERIOR À ADMISSÃO NO ENTE PARA OUTROS RPPS":
            //     servidor.tempoServicoAnteriorTotal,
            "NÚMERO DE DEPENDENTES DO SERVIDOR ATIVO":
              servidor.totalDependentes,
            "DATA DE NASCIMENTO DO CÔNJUGE DO SERVIDOR ATIVO":
              servidor.dataNascimentoConjuge,
            "CONDIÇÃO DO CÔNJUGE": "1",
            "DATA DE NASCIMENTO DO DEPENDENTE 2":
              servidor.dataNascimentoDependente2,
            "CONDIÇÃO DO DEPENDENTE 2": "1",
            "DATA DE NASCIMENTO DO DEPENDENTE 3":
              servidor.dataNascimentoDependente3,
            "CONDIÇÃO DO DEPENDENTE 3": "1",
            "SEGURADO EM ABONO DE PERMANÊNCIA ": servidor.abono_permanencia,
            "DATA DE INÍCIO DE ABONO DE PERMANÊNCIA DO SERVIDOR ATIVO":
              servidor.data_abono,
            "PREVIDÊNCIA COMPLEMENTAR": "",
            "TETO REMUNERATÓRIO ESPECÍFICO": "",
          };
          self.servidoresAtivos.push(data);
        } else if (servidor.status !== null && servidor.status == 3) {
          //PENSIONISTAS
          let data = {
            ANO: servidor.ano,
            MÊS: servidor.mes,
            CO_IBGE: servidor.ibge,
            NOME_DO_ENTE: servidor.nome_cidade,
            SG_UF: servidor.estado_naturalidade,
            CO_COMP_MASSA: "1",
            CO_TIPO_FUNDO: tipoFundo,
            CNPJ: servidor.cnpj,
            "NOME DO ÓRGÃO/ENTIDADE": servidor.orgao,
            "CO_PODER (CÓDIGO DO PODER)": poder,
            "CO_TIPO_PODER (CÓDIGO DO TIPO DE PODER)": tipoPoder,
            "CO_TIPO_INSTITUIDOR (CÓDIGO DO TIPO DE INSTITUIDOR)":
              servidor.administracao,
            "ID MATRICULA INSTITUIDOR": servidor.matricula_pensao,
            "INSTITUIDOR CPF": servidor.cpf_pensao,
            "INSTITUIDOR PIS PASEP": servidor.pis_pensao,
            "SEXO INSTITUIDOR": servidor.sexo,
            DT_NASC_INSTITUIDOR: servidor.data_nascimento_pensao
              ? moment(servidor.data_nascimento_pensao).format("DD/MM/YYYY")
              : null,
            DT_OBITO_INSTITUIDOR: servidor.data_falecimento_pensao
              ? moment(servidor.data_falecimento_pensao).format("DD/MM/YYYY")
              : null,
            MATRICULA_PENSIONISTA: servidor.matricula,
            CPF_PENSIONISTA: servidor.cpf,
            SEXO: servidor.sexo,
            DT_NASC_PENSIONISTA: servidor.dataNascimento,
            "RELAÇÃO DO PENSIONISTA COM O INSTITUIDOR":
              servidor.relacao_titular_pensao,
            "DATA INICIO PENSAO": servidor.concessao_aposentadoria,
            "VALOR BENEF PENSAO": servidor.salarioBaseMensal,
            "VALOR TOTAL DA PENSÃO": servidor.salarioBaseMensal,
            VL_PCT_QUOTA: "",
            "VALOR CONTRIBUICAO": "",
            "VALOR MENSAL DA COMPENSAÇÃO PREVIDENCIÁRIA": "",
            PARIDADE: servidor.paridade_aposentadoria,
            "CONDIÇÃO DO PENSIONISTA": servidor.deficiente == 1 ? 2 : 1,
            "DURAÇÃO DO BENEFÍCIO": servidor.tipo_pensao,
            "TEMPO DURAÇÃO DO BENEFÍCIO": "",
            "PREVIDÊNCIA COMPLEMENTAR": "",
            "TETO REMUNERATÓRIO ESPECÍFICO": "",
          };
          self.servidoresPensionistas.push(data);
        } else if (servidor.status != 4) {

          let tipo_aposentadoria = null

          if(servidor.tipo_aposentadoria == 2){
            tipo_aposentadoria = 2
          }else{
            tipo_aposentadoria = 7
          }
          //APOSENTADOS
          let data = {
            ANO: servidor.ano,
            MÊS: servidor.mes,
            CO_IBGE: servidor.ibge,
            NOME_DO_ENT: servidor.nome_cidade,
            SIGLA_ESTADO: servidor.estado_naturalidade,
            "COMPOSIÇÃO DA MASSA (A)": "1",
            "TIPO DE FUNDO (B)": servidor.fundo,
            CNPJ: servidor.cnpj,
            "NOME DO ÓRGÃO/ENTIDADE": servidor.orgao,
            PODER: poder ? poder : "",
            "CO_TIPO_PODER (CÓDIGO DO TIPO DE PODER)": tipoPoder
              ? tipoPoder
              : "",
            TIPO_AUTARQUIA: servidor.administracao,
            CO_TIPO_POPULACAO: 4, //servidor.co_tipo_populacao,
            TIPO_CARGO: tipo_aposentadoria,//servidor.tipo_cargo,
            CO_TIPO_APOSENTADORIA:
              servidor.tipo_aposentadoria == null ||
              servidor.tipo_aposentadoria == undefined
                ? ""
                : servidor.tipo_aposentadoria,
            MATRÍCULA: servidor.matricula,
            CPF: servidor.cpf,
            "PIS-PASEP": servidor.pis,
            "SEXO DO APOSENTADO": servidor.sexo,
            "ESTADO CIVIL": servidor.estadoCivil,
            "DATA DE NASCIMENTO": servidor.dataNascimento,
            DT_ING_SERV_PUB: servidor.data_ingresso_serv,
            "DATA DE INGRESSO NO ENTE": servidor.dataIngressoEnte,
            DT_INICIO_APOSENTADORIA: servidor.concessao_aposentadoria,
            VL_APOSENTADORIA: servidor.salarioBaseMensal,
            VL_CONTRIBUICAO: "",
            VL_COMPENS_PREVID: "",
            IN_PARID_SERV: servidor.paridade_aposentadoria,
            CO_CONDICAO_APOSENTADO: "1",
            NU_TEMPO_RGPS_MUN: "",
            NU_TEMPO_RPPS: "",
            NU_DEPENDENTES: servidor.totalDependentes,
            "DATA DE NASCIMENTO DO CÔNJUGE": servidor.dataNascimentoConjuge,
            "CONDIÇÃO DO CÔNJUGE": "1",
            "DATA DE NASCIMENTO DO DEPENDENTE 2":
              servidor.dataNascimentoDependente2,
            "CONDIÇÃO DO DEPENDENTE 2": "1",
            "DATA DE NASCIMENTO DO DEPENDENTE 3":
              servidor.dataNascimentoDependente3,
            "CONDIÇÃO DO DEPENDENTE 3": "1",
            "PREVIDÊNCIA COMPLEMENTAR": "",
            "TETO REMUNERATÓRIO ESPECÍFICO": "",
          };
          self.servidoresInativos.push(data);
        }
      }
      self.excelService.exportAsExcelFile(
        JSON.parse(JSON.stringify(self.servidoresAtivos)),
        "servidoresativos"
      );
      self.excelService.exportAsExcelFile(
        JSON.parse(JSON.stringify(self.servidoresInativos)),
        "servidoresinativos"
      );
      self.excelService.exportAsExcelFile(
        JSON.parse(JSON.stringify(self.servidoresPensionistas)),
        "pensionistas"
      );
      this.spinner.hide();
    }
  }
  async deletar(chave, id_backup) {
    const self = this;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const data = await this.apiCall
      .get(`banco-dados/files/delete?id_backup=${id_backup}&chave=${chave}`)
      .pipe(take(1))
      .toPromise();
    this.spinner.hide();
    self.ngOnInit();
  }
  async downloadArquivo(chave) {
    const self = this;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const url = await this.apiCall
      .get(`banco-dados/files/url`)
      .pipe(take(1))
      .toPromise();
    saveAs(url + chave, chave);
    this.spinner.hide();
  }
}
