import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/page/servidor/inicio', title: 'Início', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/servidores', title: 'Base de Dados', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '', title: 'Relatórios', icon: 'ft-database submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
    { path: '/page/servidor/relatorio/base', title: 'Base', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/page/servidor/relatorio/planilha', title: 'Planilha', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/page/servidor/relatorio/grafico', title: 'Gráficos', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/page/servidor/relatorio/estatisticas', title: 'Estatísticas', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/page/servidor/relatorio/dinamico', title: 'Dinâmico', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  ]
  },
  {
    path: '/page/servidor/simulador', title: 'Simulador Aposentadoria', icon: 'ft-dollar-sign', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  
/*  {
    path: '/seller', title: 'Área do Personal', icon: 'ft-align-left', class: 'has-sub', isExternalLink: false,
    submenu: [
        { path: '/page/personal/avaliacoes', title: 'Avaliações', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/page/personal/treinos', title: 'Treinos', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/page/personal/desafios/2', title: 'Desafios', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/page/personal/programas/1', title: 'Programas', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/page/personal/exercicios', title: 'Exercícios', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/page/personal/categorias', title: 'Categorias', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/page/personal/mural', title: 'Mural', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
},*/
];
/*
{
  path: '', title: 'Treinos', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  submenu: [
  { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
]
},*/
