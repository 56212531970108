<div class="container">
  <h2 class="mb-4">Benefícios do Servidor</h2>
  <!-- Benefícios do Servidor -->
  <div class="server-data">
    <div class="card p-3 shadow-sm">
      <div class="row align-items-center">
        <!-- Coluna da imagem -->
        <div class="col-auto">
          <img
            [src]="
              servidor && servidor.imagem
                ? servidor.imagem
                : 'assets/img/portrait/small/no-avatar.svg'
            "
            alt="Foto do servidor"
            class="rounded-circle"
            style="width: 80px; height: 80px; object-fit: cover"
          />
        </div>
        <!-- Coluna das informações -->
        <div class="col">
          <h3 class="mb-1">
            {{ servidor && servidor.nome ? servidor.nome : "-----" }}
          </h3>
          <div class="d-flex align-items-center">
            <p class="mb-0">
              <b style="font-weight: 600">CPF:</b>
              {{ servidor && servidor.cpf ? servidor.cpf : "-----" }}
            </p>
            <p class="mb-0 ms-3 mr-2 ml-2">|</p>
            <span class="badge bg-success text-white ms-3 pr-1">Ativo</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Dados de Cadastro -->
  <div class="registration-data">
    <div class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h4>
          Dados do Servidor
          <i
            [ngClass]="{
              'fa fa-check-circle text-success': allDataValid,
              'fa fa-exclamation-triangle text-warning': !allDataValid
            }"
            aria-hidden="true"
          ></i>
        </h4>
        <button class="btn-no-border" (click)="toggleInfo()">
          {{ isVisible ? "Ocultar" : "Mostrar" }}
        </button>
      </div>

      <div class="card-body" *ngIf="isVisible">
        <!-- Alerta para dados faltando -->
        <div
          *ngIf="missingFields.length > 0"
          class="alert alert-warning mb-3"
          role="alert"
        >
          <p>
            <strong>Atenção!</strong> Os seguintes dados estão faltando no
            cadastro do servidor:
          </p>
          <ul>
            <li *ngFor="let field of missingFields">{{ field }}</li>
          </ul>
        </div>

        <ngb-tabset type="tabs" class="custom-tabs">
          <ngb-tab title="Informações Básicas">
            <ng-template ngbTabContent>
              <table class="table">
                <tr>
                  <td style="border-top: none">Nome Completo</td>
                  <td style="border-top: none">
                    {{ servidor && servidor.nome ? servidor.nome : "-----" }}
                  </td>
                </tr>
                <tr>
                  <td>CPF</td>
                  <td>
                    {{ documentos?.cpf ? (documentos?.cpf | mask: '000.000.000-00') : '-----' }}

                  </td>
                </tr>
                <tr>
                  <td>RG</td>
                  <td>
                    {{ documentos && documentos.rg ? documentos.rg : "-----" }}
                  </td>
                </tr>
                <tr>
                  <td>Emissão RG</td>
                  <td>
                    {{
                      documentos && documentos.data_emissao_rg
                        ? formatDateByString(documentos.data_emissao_rg)
                        : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Órgão Emissor</td>
                  <td>
                    {{
                      documentos && documentos.uf_rg
                        ? documentos.uf_rg
                        : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Sexo</td>
                  <td>
                    {{ servidor?.sexo ? (servidor.sexo.toLowerCase() === 'f' ? 'Feminino' : 'Masculino') : '-----' }}
                  </td>
                </tr>
                <tr>
                  <td>Nacionalidade</td>
                  <td>BRASILEIRA</td>
                </tr>
                <tr>
                  <td>Nascimento</td>
                  <td>
                    {{
                      servidor && servidor.data_nascimento
                        ? formatDateByString(servidor.data_nascimento)
                        : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Nome do Pai</td>
                  <td>
                    {{
                      servidor && servidor.nome_pai
                        ? servidor.nome_pai
                        : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Nome da Mãe</td>
                  <td>
                    {{
                      servidor && servidor.nome_mae
                        ? servidor.nome_mae
                        : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Estado Civil</td>
                  <td>
                    {{
                      servidor && servidor.estado_civil
                        ? getEstadoCivil(servidor.estado_civil)
                        : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>PIS</td>
                  <td>
                    {{
                      documentos && documentos.nit ? (documentos?.nit | mask: '000.00000.00-0') : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Título Eleitor</td>
                  <td>
                    {{
                      documentos && documentos.titulo_eleitor
                        ? (documentos?.titulo_eleitor | mask: '000000000000')
                        : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>E-mail</td>
                  <td>
                    {{ contatos && contatos.email ? (contatos.email === 'null' ? '-----' : contatos.email ) : "-----" }}
                  </td>
                </tr>
              </table>
            </ng-template>
          </ngb-tab>

          <ngb-tab title="Endereço">
            <ng-template ngbTabContent>
              <table class="table">
                <tr>
                  <td style="border-top: none">Cidade</td>
                  <td style="border-top: none">
                    {{
                      endereco && endereco.cidade ? endereco.cidade : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Endereço</td>
                  <td>
                    {{
                      endereco && endereco.logradouro
                        ? endereco.logradouro
                        : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Número</td>
                  <td>
                    {{
                      endereco && endereco.numero ? endereco.numero : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Complemento</td>
                  <td>
                    {{
                      endereco && endereco.complemento
                        ? endereco.complemento
                        : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Bairro</td>
                  <td>
                    {{
                      endereco && endereco.bairro ? endereco.bairro : "-----"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>CEP</td>
                  <td>
                    {{ endereco && endereco.cep ? endereco.cep : "-----" }}
                  </td>
                </tr>
              </table>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>

  <!-- Informações Básicas do Benefício -->
  <div class="benefit-info">
    <div class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h4>Benefícios</h4>
        <button class="btn-no-border" (click)="toggleBenefit()">
          {{ isBenefitVisible ? "Ocultar" : "Mostrar" }}
        </button>
      </div>

      <div class="card-body" *ngIf="isBenefitVisible">
        <!-- Estado: Sem Benefícios -->
        <div *ngIf="beneficios && beneficios.length === 0" class="no-benefits">
          <!-- Alerta -->
          <div class="alert alert-warning mb-3" role="alert">
            <i class="fa fa-exclamation-triangle"></i> É necessário ao menos um
            item cadastrado.
          </div>
          <!-- Mensagem de orientação -->
          <p class="text-muted text-center">
            Adicione um novo item clicando no botão "Adicionar"
          </p>
        </div>

        <!-- Estado: Benefícios Cadastrados -->
        <div
          *ngFor="let beneficio of beneficios"
          class="card mt-3 benefit-card"
        >
          <div class="card-body">
            <!-- Título e Pill -->
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title text-start">
                {{ getGeneroBeneficioNome(beneficio.genero_beneficio) }}
              </h5>
              <span
                class="badge-pill"
                [ngClass]="beneficio.isValid ? 'pill-success' : 'pill-danger'"
              >
                {{ beneficio.isValid ? "CADASTRADO" : "INVÁLIDO" }}
              </span>
            </div>
            <div class="row">
              <!-- Informações do Benefício -->
              <div class="col-8 card-text mt-3">
                <p>
                  <strong>Espécie:</strong>
                  {{ getEspecieBeneficioNome(beneficio.especie) }}
                </p>
                <p>
                  <strong>Modalidade:</strong>
                  {{ getModalidadeBeneficioNome(beneficio.modalidade) }}
                </p>
                <p>
                  <strong>Legislação:</strong>
                  {{ getLegislacaoNome(beneficio.tipo_legislacao) }}
                </p>
                <p>
                  <strong>Média Salarial:</strong>
                  {{
                    beneficio.media_salarial
                      ? beneficio.media_salarial
                      : "R$ 0,00"
                  }}
                </p>
              </div>

              <!-- Botões de Ação -->
              <div class="col-4 actions d-flex justify-content-end align-items-center">
                <button
                  class="btn btn-outline-danger"
                  (click)="deleteBeneficio(beneficio.id)"
                >
                  <i class="fa fa-trash"></i>
                </button>
                <button
                  class="btn btn-outline-success"
                  (click)="editBeneficio(beneficio.id)"
                >
                  <i class="fa fa-edit"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Botão Adicionar -->
        <div class="mt-4 text-right">
          <button class="btn btn-primary" (click)="openCadastroBeneficio()">
            Adicionar Benefício
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Ações -->
  <div class="actions">
    <button class="btn btn-primary">Voltar</button>
    <button class="btn btn-success" (click)="onGenerateXML()">Gerar XML</button>
  </div>
</div>
<ng-template #cadastroBeneficio let-modal>
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Cadastro de Benefício</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form [formGroup]="informacoesBeneficioForm">
              <!-- Informações Básicas -->
              <h5>Informações Básicas</h5>
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="generoBeneficio">Gênero do Benefício</label>
                    <ng-select
                      [appendTo]="'body'"
                      [items]="generoBeneficio"
                      bindLabel="nome"
                      bindValue="id"
                      placeholder="Gênero Benefício"
                      id="generoBeneficio"
                      formControlName="generoBeneficio"
                    ></ng-select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="especie">Espécie</label>
                    <ng-select
                      [appendTo]="'body'"
                      [items]="especieBeneficio"
                      bindLabel="nome"
                      bindValue="id"
                      placeholder="Especie Benefício"
                      id="especieBeneficio"
                      formControlName="especieBeneficio"
                    ></ng-select>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="generoBeneficio">Modalidade do Benefício</label>
                    <ng-select
                      [appendTo]="'body'"
                      [items]="modalidadeBeneficio"
                      bindLabel="nome"
                      bindValue="id"
                      placeholder="Modalidade"
                      id="modalidadeBeneficio"
                      formControlName="modalidadeBeneficio"
                    ></ng-select>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="matricula">Matrícula</label>
                    <input
                      type="number"
                      id="matricula"
                      class="form-control"
                      formControlName="matricula"
                      placeholder="Insira a matrícula"
                      min="0"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="identificacaoFuncional"
                      >Identificação Funcional</label
                    >
                    <input
                      type="text"
                      id="identificacaoFuncional"
                      class="form-control"
                      formControlName="identificacaoFuncional"
                      placeholder="Insira a identificação funcional"
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="cargo">Cargo</label>
                    <input
                      type="text"
                      id="cargo"
                      class="form-control"
                      formControlName="cargo"
                      placeholder="Insira o cargo"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="regimeJuridico">Regime Jurídico</label>
                    <ng-select
                      [appendTo]="'body'"
                      [items]="regimeJuridico"
                      bindLabel="nome"
                      bindValue="id"
                      placeholder="Modalidade"
                      id="regimeJuridico"
                      formControlName="regimeJuridico"
                    ></ng-select>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="cargaHoraria">Carga Horária</label>
                    <input
                      type="number"
                      id="cargaHoraria"
                      class="form-control"
                      formControlName="cargaHoraria"
                      placeholder="Insira a carga horária"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="padrao">Padrão</label>
                    <input
                      type="text"
                      id="padrao"
                      class="form-control"
                      formControlName="padrao"
                      placeholder="Insira o padrão"
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="nivel">Nível</label>
                    <input
                      type="text"
                      id="nivel"
                      class="form-control"
                      formControlName="nivel"
                      placeholder="Insira o nível"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="classe">Classe</label>
                    <input
                      type="text"
                      id="classe"
                      class="form-control"
                      formControlName="classe"
                      placeholder="Insira a classe"
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="outros">Outros</label>
                    <input
                      type="text"
                      id="outros"
                      class="form-control"
                      formControlName="outros"
                      placeholder="Informações adicionais"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="escolaridade">Escolaridade</label>
                    <input
                      type="text"
                      id="escolaridade"
                      class="form-control"
                      formControlName="escolaridade"
                      placeholder="Insira a escolaridade"
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="observacoes">Observações</label>
                    <input
                      type="text"
                      id="observacoes"
                      class="form-control"
                      formControlName="observacoes"
                      placeholder="Insira observações"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="tituloEleitor">Título de Eleitor</label>
                    <input
                      type="text"
                      id="tituloEleitor"
                      class="form-control"
                      formControlName="tituloEleitor"
                      placeholder="Insira o título de eleitor"
                    />
                  </div>
                </div>
              </div>

              <hr>

              <!-- Informações Legislativas -->
              <h5 class="mt-4">Informações Legislativas</h5>
              <div class="form-row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="codigoLegislativo">Código</label>
                    <input
                      type="text"
                      id="codigoLegislativo"
                      class="form-control"
                      formControlName="codigoLegislativo"
                      placeholder="Insira o código"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="cpfResponsavel">CPF Responsável</label>
                    <input
                      type="text"
                      mask="000.000.000-00"
                      id="cpfResponsavel"
                      class="form-control"
                      formControlName="cpfResponsavel"
                      placeholder="CPF Responsável"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="cpfSignatario">CPF Signatário</label>
                    <input
                      type="text"
                      mask="000.000.000-00"
                      id="cpfSignatario"
                      class="form-control"
                      formControlName="cpfSignatario"
                      placeholder="CPF Signatário"
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="tipoLegislacao">Tipo da Legislação</label>
                    <ng-select
                      [appendTo]="'body'"
                      [items]="tipoLegislacao"
                      bindLabel="nome"
                      bindValue="id"
                      placeholder="Tipo Legislação"
                      id="tipoLegislacao"
                      formControlName="tipoLegislacao"
                    ></ng-select>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="numeroLegislacao">Número da Legislação</label>
                    <input
                      type="text"
                      id="numeroLegislacao"
                      class="form-control"
                      formControlName="numeroLegislacao"
                      placeholder="Insira o número da legislação"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="anoLegislacao">Ano da Legislação</label>
                    <input
                      type="text"
                      id="anoLegislacao"
                      class="form-control"
                      formControlName="anoLegislacao"
                      placeholder="Ano da legislação"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="dataInicio">Data de Início</label>
                    <input
                      type="date"
                      id="dataInicio"
                      class="form-control"
                      formControlName="dataInicio"
                      placeholder="dd-mm-yyyy"
                    />
                  </div>
                </div>
              </div>

              <hr>


              <!-- Informações Salariais -->
              <h5 class="mt-4">Informações Salariais</h5>
              <div class="form-row align-items-end">

                <div class="col-md-6">
                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-block btn-outline-primary"
                      (click)="importar()"
                    >
                      <i class="fa fa-upload"></i> Carregar Planilha
                    </button>
                  </div>
                </div>
    
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="mediaSalarial">Média Salarial</label>
                    <input
                      type="text"
                      id="mediaSalarial"
                      class="form-control"
                      formControlName="mediaSalarial"
                      placeholder="Insira a média salarial"
                    />
                  </div>
                </div>
    
                
              </div>

              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="dataDemonstrativo">Data do Demonstrativo</label>
                    <input
                      type="date"
                      id="dataDemonstrativo"
                      class="form-control"
                      formControlName="dataDemonstrativo"
                      placeholder="dd-mm-yyyy"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="assinadoPor">Assinado Por</label>
                    <input
                      type="text"
                      id="assinadoPor"
                      class="form-control"
                      formControlName="assinadoPor"
                      placeholder="Assinado por"
                    />
                  </div>
                </div>
              </div>

              <hr>


              <!-- Informações Judiciais -->
              <h5 class="mt-4">Informações Judiciais</h5>
              <div class="form-row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="tipoAto">Tipo Ato</label>
                    <input
                      type="text"
                      id="tipoAto"
                      class="form-control"
                      formControlName="tipoAto"
                      placeholder="Insira o tipo de ato"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="numeroAto">Número do Ato</label>
                    <input
                      type="text"
                      id="numeroAto"
                      class="form-control"
                      formControlName="numeroAto"
                      placeholder="Insira o número do ato"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="anoAto">Ano do Ato</label>
                    <input
                      type="text"
                      id="anoAto"
                      class="form-control"
                      formControlName="anoAto"
                      placeholder="Insira o ano do ato"
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="valorProvento">Valor do Provento</label>
                    <input
                      type="text"
                      id="valorProvento"
                      class="form-control"
                      formControlName="valorProvento"
                      placeholder="Insira o valor do provento"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="vantagens">Vantagens</label>
                    <input
                      type="text"
                      id="vantagens"
                      class="form-control"
                      formControlName="vantagens"
                      placeholder="Insira as vantagens"
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="descricaoOrgao">Complemento Salarial</label>
                    <ng-select
                      [appendTo]="'body'"
                      [items]="tipoEscolha"
                      bindLabel="nome"
                      bindValue="id"
                      placeholder="Complemento"
                      id="complementoSalarial"
                      formControlName="complementoSalarial"
                    ></ng-select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="descricaoOrgao"
                      >Descrição do Órgão Custeador</label
                    >
                    <input
                      type="text"
                      id="descricaoOrgao"
                      class="form-control"
                      formControlName="descricaoOrgao"
                      placeholder="Insira a descrição do órgão"
                    />
                  </div>
                </div>
              </div>

              <!-- Botões de ação -->
              <div class="form-row">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                    (click)="onSubmitBaseBenefit()"
                    [disabled]="informacoesBeneficioForm.invalid"
                  >
                    {{
                      informacoesBeneficioForm.valid ? "Atualizar" : "Cadastrar"
                    }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ngx-spinner></ngx-spinner>
